import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MacAddressProps } from 'app-context/types';
import { css } from '@emotion/css';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { BLUE_MACHINE_FILTER } from 'helpers/colors';
import { BadgeTag, FilterIcon } from '.';

const iconDots = require('img/draggabledots.svg') as string;

interface DraggableMacAddressProps {
  index: number;
  mac: MacAddressProps;
  position: string;
  showMacAddresses: boolean;
  showTags: boolean;
}

export const DraggableMacAddress: React.FunctionComponent<DraggableMacAddressProps> = ({
  index,
  mac,
  position,
  showMacAddresses,
  showTags,
}) => {
  const { state } = useAppState();
  const { macAddresses } = state;

  const macAddressBgColor = position === 'left' ? BLUE_MACHINE_FILTER : '#effbff';
  const styles = useStyles2(() => getStyles(macAddressBgColor));

  const isOtherTags = mac.otherTags?.filter((tag) => tag.label.toLowerCase() !== 'machine').length !== 0;

  return (
    <Draggable key={mac.macAddress} draggableId={mac.macAddress} index={index}>
      {(provided, snapshot) => (
        // @ts-ignore
        <div
          className={styles.macAddressAndTagsContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div className={styles.macAddressContainer}>
            {/* Draggable mac address */}
            <div className={styles.textContainer}>
              <FilterIcon icon={iconDots} size={'18px'} />
              <div className={styles.macAddressText}>{showMacAddresses ? mac.macAddress : mac.dashboardName}</div>
            </div>
          </div>
          {isOtherTags && showTags && (
            <div
              className={css`
                text-align: right;
                padding: 8px 3px 6px 0;
                width: 100%;
              `}>
              <BadgeTag macAddresses={macAddresses} mac={mac} />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

const getStyles = (macAddressBgColor: string) => {
  return {
    textContainer: css`
      display: flex;
      align-items: center;
    `,
    macAddressAndTagsContainer: css`
      align-items: center;
      background: ${macAddressBgColor};
      border-radius: 2px;
      box-shadow: #0000001f 0px 1px 3px, #0000003d 0px 1px 2px;
      color: #062d6a;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      justify-content: space-between;
      margin: 6px;
    `,
    macAddressContainer: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      overflow: hidden;
      padding: 4px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    macAddressText: css`
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    draggableMacAddress: css`
      margin: 2px;
      min-height: 45px;
      border: 2px dashed #9dc3ff;
      height: 100%;
    `,
    sideBarMacAddress: css`
      min-height: 120px;
      width: 278px;
      max-height: 210px;
      overflow: auto;
    `,
    noFilterMessage: css`
      align-items: center;
      display: flex;
      font-size: 13px;
      font-style: italic;
      font-weight: 200;
      justify-content: center;
      width: 100%;
    `,
  };
};
