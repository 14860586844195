import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MacAddressProps } from 'app-context/types';
import { getDraggingOverStyle } from 'components/filters/helpersFilters';
import { css } from '@emotion/css';
import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DraggableMacAddress } from './DraggableMacAddress';

interface DroppableAreaForMacAddressesProps {
  droppableIdCustom: string;
  filterId: string;
  showMacAddresses: boolean;
  showTags: boolean;
}

export const DroppableAreaForMacAddresses: React.FunctionComponent<DroppableAreaForMacAddressesProps> = ({
  droppableIdCustom,
  filterId,
  showMacAddresses,
  showTags,
}) => {
  const { state } = useAppState();
  const { dictionary_uiElements, macAddresses } = state;
  const { TR_dragAndDropDashboards, TR_dragAndDropMacAddresses } = dictionary_uiElements;
  const styles = useStyles2(getStyles);
  const macAddressesInFolder = (mac: MacAddressProps) => mac.machineFilterId === filterId;

  return (
    <Droppable droppableId={droppableIdCustom} type={'MACHINE'} key={droppableIdCustom}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={styles.draggableMacAddress}
          style={getDraggingOverStyle(snapshot.isDraggingOver)}>
          {!macAddresses.filter(macAddressesInFolder).length && (
            <div className={styles.noFilterMessage}>
              {showMacAddresses ? TR_dragAndDropMacAddresses : TR_dragAndDropDashboards}
            </div>
          )}
          {/*  list of mac addresses associated to machine filter */}
          {macAddresses &&
            macAddresses
              ?.filter(macAddressesInFolder)
              .map((mac, index) => (
                <DraggableMacAddress
                  mac={mac}
                  index={index}
                  showMacAddresses={showMacAddresses}
                  position={'right'}
                  showTags={showTags}
                  key={mac.macAddress}
                />
              ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const getStyles = () => {
  return {
    macAddressAndTagsContainer: css`
      align-items: center;
      background: #effbff;
      border-radius: 2px;
      box-shadow: #0000001f 0px 1px 3px, #0000003d 0px 1px 2px;
      color: #062d6a;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      justify-content: space-between;
      margin: 6px;
    `,
    macAddressContainer: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      overflow: hidden;
      padding: 4px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    draggableMacAddress: css`
      margin: 2px;
      min-height: 45px;
      border: 2px dashed #9dc3ff;
      height: 100%;
    `,
    sideBarMacAddress: css`
      min-height: 120px;
      width: 278px;
      max-height: 210px;
      overflow: auto;
    `,
    noFilterMessage: css`
      align-items: center;
      display: flex;
      font-size: 13px;
      font-style: italic;
      font-weight: 200;
      justify-content: center;
      width: 100%;
    `,
  };
};
