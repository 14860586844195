import { SelectableValue, PanelData } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { useAppState } from 'app-context/AppStateContext';
import { AlertsColumnProps, DashboardWithAlerts, FilterProps, SelectStatusProps } from 'app-context/types';
//@ts-ignore
import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useMemo, useState } from 'react';
import {
  filterByFolder,
  filterByMachine,
  filterByTag,
  filterDashboardByAlertStatus,
  getAlertsCounters,
  getMachinesInSelectedFolders,
} from 'helpers/helpersFilters';
import { getDatasources, getJson, getTags } from 'helpers';
import { getDashboardsAndAlerts, getUserFolders } from 'helpers/requests';
import { CountAlerts, CountAlertsHeader } from 'components';
import { NotificationError } from '../shared/NotificationMessage';
import { URL_DICO_JSON } from 'helpers/URLS';

export const useMainContainer = (data: PanelData) => {
  const { state, dispatch } = useAppState();
  const {
    alertsColumns,
    dashboardsWithAlerts,
    datasourceMysql,
    dictionary_uiElements,
    lang,
    machineFilters,
    pauseTrigger,
    selectedDashboards,
    selectedFolders,
    selectedMachines,
    selectedStatus,
    selectedTags,
    tags,
    user,
  } = state;
  // Dropdown menu of machine filters depends of the folders selected, it's necessary to keep the original machine filters array unmutable
  const [machineFiltersOptions, setMachineFiltersOptions] = useState<FilterProps[]>();
  const [foldersList, setFoldersList] = useState<FilterProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const grafanaVariables = getTemplateSrv().getVariables();

  const fetchDatasources = async () => {
    const datasources = await getDatasources(grafanaVariables);
    if (!datasources) {
      NotificationError('No datasources found');
      return;
    }
    dispatch({
      type: 'SET_DATASOURCES',
      payload: { mysql: datasources.mysql, influx: datasources.influx, json: datasources.json },
    });
  };

  const fetchAlertsAndDashboardInfo = async () => {
    const { TR_battery, TR_anomalyScore, TR_vibratorySeverity, TR_vibratorySeverityAlarm, TR_vibratorySeverityTrip } =
      dictionary_uiElements;

    const commonAlerts = {
      battery: TR_battery,
      anomalyScore: TR_anomalyScore,
      vibrationSev: TR_vibratorySeverity,
      vibrationSevAlarm: TR_vibratorySeverityAlarm,
      vibrationSevTrip: TR_vibratorySeverityTrip,
    };

    const allDashboards = await getDashboardsAndAlerts(user.orgRole, datasourceMysql.id, alertsColumns, commonAlerts);

    dispatch({ type: 'SET_DASHBOARDS_WITH_ALERTS', payload: allDashboards?.dashboards as DashboardWithAlerts[] });
    dispatch({ type: 'SET_ALERTS_COLUMNS', payload: allDashboards?.alertColumns as AlertsColumnProps[] });
    setIsLoading(false);
  };

  /** Get all tags and set machine filters */
  const fetchTags = async () => {
    const tags = await getTags();
    if (tags?.otherTags) {
      dispatch({ type: 'SET_TAGS', payload: tags.otherTags });
    }
    if (tags?.machineFilters) {
      dispatch({ type: 'SET_MACHINE_FILTERS', payload: tags?.machineFilters });
    }
  };

  /** Get translated ui elements */
  const getUiElements = async () => {
    const dico = await getJson(`${URL_DICO_JSON}${lang}.json`).catch((err: any) => {
      console.log('error while getting dictionnary', err);
      NotificationError(err);
    });

    if (typeof dico === 'object') {
      if (Object.keys(dico).length && Object.keys(dico?.ui_elements).length) {
        dispatch({ type: 'ADD_TRANSLATED_UI_TO_DICO', payload: dico.ui_elements });
      }
    }
  };

  /** Get all grafana folders (included General folder) depending on user permissions */
  const fetchUserFolders = async () => {
    const folderOptions = await getUserFolders();
    setFoldersList(folderOptions as FilterProps[]);
  };

  const getTotalDashboardAlerts = (dashboardAlerts: DashboardWithAlerts[], isHeader: boolean) => {
    const countAlerts = getAlertsCounters(dashboardAlerts);
    return isHeader ? (
      <CountAlertsHeader countAlerts={countAlerts} selectedStatus={selectedStatus} />
    ) : (
      <CountAlerts countAlerts={countAlerts} selectedStatus={selectedStatus} />
    );
  };

  /** Handle of folder selection */
  const filterDashboardsByFolder = (folderOptions: SelectableValue[]) => {
    dispatch({ type: 'SET_SELECTED_MACHINES', payload: [] });
    dispatch({ type: 'SET_SELECTED_TAGS', payload: [] });

    // default display when no folder is selected
    if (!folderOptions.length) {
      dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
      const allDashboards = filterDashboardByAlertStatus(dashboardsWithAlerts, selectedStatus);
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: allDashboards });
      setMachineFiltersOptions(machineFilters);
      return;
    }

    const selectedFoldersDashboards = dashboardsWithAlerts.filter(
      (dashboard) => folderOptions.findIndex((option) => dashboard.folderId === option.value) !== -1
    );

    const newDashboards: DashboardWithAlerts[] = filterDashboardByAlertStatus(
      selectedFoldersDashboards,
      selectedStatus
    );

    dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: newDashboards });
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: folderOptions as FilterProps[] });

    // Get machine filters in the selected folder(s)
    const newMachines = getMachinesInSelectedFolders(newDashboards, machineFilters);
    setMachineFiltersOptions(newMachines);
  };

  useEffect(() => {
    fetchDatasources();
    getUiElements();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Trick to catch refresh event (data.request is changing at each refresh) */
  useEffect(() => {
    if (Object.keys(datasourceMysql).length) {
      fetchUserFolders();
      fetchTags();
      if (Object.keys(dictionary_uiElements).length) {
        fetchAlertsAndDashboardInfo();
      }
    }
  }, [data?.request, datasourceMysql, dictionary_uiElements, pauseTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dashboardsWithAlerts.length) {
      const copyDashboards = cloneDeep(dashboardsWithAlerts);
      const newDashboards: DashboardWithAlerts[] = filterDashboardByAlertStatus(copyDashboards, selectedStatus);

      if (!selectedDashboards.length) {
        /** Get variables from synthetic dashboard json */
        const variablesJson = getTemplateSrv().getVariables();
        const tagsVariableIndex = variablesJson.findIndex((variable) => variable.name === 'tags_selection');
        const tagVariableIndex = variablesJson.findIndex((variable) => variable.name === 'tag_selection'); // backward compatibility
        const machinesVariableIndex = variablesJson.findIndex((variable) => variable.name === 'machines_selection');
        const foldersVariableIndex = variablesJson.findIndex((variable) => variable.name === 'folders_selection');
        const alertStatesVariableIndex = variablesJson.findIndex(
          (variable) => variable.name === 'alertstate_selection'
        );

        if (
          tagsVariableIndex === -1 ||
          machinesVariableIndex === -1 ||
          foldersVariableIndex === -1 ||
          alertStatesVariableIndex === -1
        ) {
          NotificationError({ message: 'Some variables are missing in the Synthetic Dashboard settings' });
          return;
        }

        const queryFolders = getTemplateSrv().replace(`$folders_selection`);
        const queryMachines = getTemplateSrv().replace(`$machines_selection`);
        const queryTags = getTemplateSrv().replace(`$tags_selection`);
        const queryAlertState = getTemplateSrv().replace(`$alertstate_selection`);
        const queryTag = variablesJson[tagVariableIndex].label;

        // Inject selected folders
        if (queryFolders) {
          const getFoldersOptions = queryFolders.split(',');
          const varFoldersOptions: FilterProps[] = [];

          for (const varFolder of getFoldersOptions) {
            const getFolderSelectableValue = varFolder.split(':');
            const folderId = parseInt(getFolderSelectableValue[0].trim(), 10);
            // check if folder selected exists in folder list
            const folderOptionIndex = foldersList.findIndex((folder) => folder.value === folderId);
            if (getFolderSelectableValue.length === 2 && folderOptionIndex !== -1) {
              varFoldersOptions.push(foldersList[folderOptionIndex]);
            }
          }

          if (varFoldersOptions.length) {
            const selectedFoldersDashboards = newDashboards.filter(
              (dashboard) => varFoldersOptions.findIndex((folder) => dashboard.folderId === folder.value) !== -1
            );
            dispatch({ type: 'SET_SELECTED_FOLDERS', payload: varFoldersOptions });
            const newMachines = getMachinesInSelectedFolders(selectedFoldersDashboards, machineFilters);
            setMachineFiltersOptions(newMachines);
            dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: selectedFoldersDashboards });
          }
        }

        // Inject selected states
        if (queryAlertState) {
          const newSelectedStatus: SelectStatusProps = { alerting: false, ok: false, noData: false, paused: false };
          const getAlertStateOptions = queryAlertState.split(',');
          for (const alert of getAlertStateOptions) {
            newSelectedStatus[`${alert}`] = true;
          }
          dispatch({ type: 'SET_SELECTED_STATUS', payload: newSelectedStatus });
        }

        // Inject selected machines
        if (queryMachines) {
          const getMachinesOptions = queryMachines.split(',');
          const varMachinesOptions: FilterProps[] = [];
          for (const varMachine of getMachinesOptions) {
            const machineOptionIndex = machineFilters.findIndex((machine) => machine.value === varMachine);
            if (machineOptionIndex !== -1) {
              varMachinesOptions.push(machineFilters[machineOptionIndex]);
            }
          }
          dispatch({ type: 'SET_SELECTED_MACHINES', payload: varMachinesOptions });
        }

        // Inject selected tag (backward compatibility)
        if (queryTag) {
          const vartagsOptions: FilterProps[] = [];
          const tagOptionIndex = tags.findIndex((tag) => tag.value === queryTag);
          if (tagOptionIndex !== -1) {
            vartagsOptions.push(tags[tagOptionIndex]);
          }
          dispatch({ type: 'SET_SELECTED_TAGS', payload: vartagsOptions });
          dispatch({
            type: 'SET_SELECTED_DASHBOARDS',
            payload: newDashboards.filter((newdash) =>
              selectedDashboards.find((selectDash) => selectDash.dashboardUid === newdash.dashboardUid)
            ),
          });
          return;
        }
        // Inject selected tags
        if (queryTags) {
          const getTagsOptions = queryTags.split(',');
          const vartagsOptions: FilterProps[] = [];
          for (const vartag of getTagsOptions) {
            const tagOptionIndex = tags.findIndex((tag) => tag.value === vartag);
            if (tagOptionIndex !== -1) {
              vartagsOptions.push(tags[tagOptionIndex]);
            }
          }
          dispatch({ type: 'SET_SELECTED_TAGS', payload: vartagsOptions });
        }
        return;
      }
      dispatch({
        type: 'SET_SELECTED_DASHBOARDS',
        payload: newDashboards.filter((newdash) =>
          selectedDashboards.find((selectDash) => selectDash.dashboardUid === newdash.dashboardUid)
        ),
      });
    }
  }, [dashboardsWithAlerts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (machineFilters.length) {
      setMachineFiltersOptions(machineFilters);
    }
  }, [machineFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When alert state is changed, filter alert dashboards by these statuses
   */
  useEffect(() => {
    if (selectedDashboards.length && dashboardsWithAlerts.length) {
      const originalSelectedDashboards = dashboardsWithAlerts.filter((dashboard) => {
        const dashboardIndex = selectedDashboards.findIndex(
          (selected) => selected.dashboardUid === dashboard.dashboardUid
        );
        if (dashboardIndex !== -1) {
          return true;
        }
        return false;
      });
      const newDashboards = filterDashboardByAlertStatus(originalSelectedDashboards, selectedStatus);
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: newDashboards });
    }
  }, [selectedStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedMachines.length && !selectedFolders.length && !selectedTags.length && dashboardsWithAlerts.length) {
      dispatch({
        type: 'SET_SELECTED_DASHBOARDS',
        payload: filterDashboardByAlertStatus(dashboardsWithAlerts, selectedStatus),
      });
      return;
    }

    if (selectedTags.length) {
      if (selectedFolders.length) {
        let newDashboardsInFolder = filterByFolder(dashboardsWithAlerts, selectedFolders);
        let newTagDashboards = filterByTag(newDashboardsInFolder, selectedTags);
        const dashboardsWithTagsFilteredByStatus = filterDashboardByAlertStatus(newTagDashboards, selectedStatus);
        dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: dashboardsWithTagsFilteredByStatus });
        return;
      }
      let newDashboards = filterByTag(dashboardsWithAlerts, selectedTags);
      const dashboardsWithMachinesFilteredByStatus = filterDashboardByAlertStatus(newDashboards, selectedStatus);
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: dashboardsWithMachinesFilteredByStatus });
      return;
    }

    if (selectedMachines.length) {
      if (selectedFolders.length) {
        let newDashboardsInFolder = filterByFolder(dashboardsWithAlerts, selectedFolders);
        let newMachineDashboards = filterByMachine(newDashboardsInFolder, selectedMachines);
        const dashboardsWithMachinesFilteredByStatus = filterDashboardByAlertStatus(
          newMachineDashboards,
          selectedStatus
        );
        dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: dashboardsWithMachinesFilteredByStatus });
        return;
      }
      let newDashboards = filterByMachine(dashboardsWithAlerts, selectedMachines);
      const dashboardsWithMachinesFilteredByStatus = filterDashboardByAlertStatus(newDashboards, selectedStatus);
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: dashboardsWithMachinesFilteredByStatus });
      return;
    }

    if (selectedFolders.length) {
      let newDashboards = filterByFolder(dashboardsWithAlerts, selectedFolders);
      const dashboardsWithMachinesFilteredByStatus = filterDashboardByAlertStatus(newDashboards, selectedStatus);
      dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: dashboardsWithMachinesFilteredByStatus });
      return;
    }
  }, [selectedFolders, selectedMachines, selectedStatus, selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const dashboardsFiltered = useMemo(() => selectedDashboards, [selectedDashboards]);

  return {
    dashboardsFiltered,
    filterDashboardsByFolder,
    foldersList,
    getTotalDashboardAlerts,
    isLoading,
    machineFiltersOptions,
  };
};
